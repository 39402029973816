import { Col, Row, Skeleton } from "antd";
import { HTMLAttributes } from "react";
import { SpeakerCard } from "../../components/speaker-card";
import jobstackService from "../../services/jobstack.service";
import { SpeakersSectionContainer } from "./styles/speakers-section";
import { useQuery } from "@tanstack/react-query";

export const SpeakersSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const { data: speakersData, isLoading: speakersDataLoading } = useQuery({
        queryKey: ["speakers"],
        queryFn: () =>
            jobstackService.getSpeakers({
                page: 1,
                pageSize: 100
            }),
        keepPreviousData: true
    });

    return (
        <SpeakersSectionContainer id="speakers">
            <Skeleton loading={speakersDataLoading}>
                <Row gutter={24} className="d-flex align-items-center " style={{ backgroundColor: "#fff" }}>
                    <Col xs={24}>
                        <Row className="mb-5">
                            <h2 style={{ color: "#000" }}>Previous Speakers</h2>
                        </Row>
                        <Row gutter={16}>
                            {speakersData?.data.map((speaker) => (
                                <Col xs={12} lg={8} xl={6} key={speaker.id} className="mb-5">
                                    <SpeakerCard name={speaker.name} photo={speaker.photo} title={speaker.title} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Skeleton>
        </SpeakersSectionContainer>
    );
};
