import { Row } from "antd";
import { HTMLAttributes, useEffect } from "react";
import {
    AboutSection,
    AgendaSection,
    CTASection,
    CompanyJoinSection,
    ExhibitorsSection,
    Footer,
    SpeakersSection,
    TicketSection,
    WelcomeSection
} from "../container";
import { StatsSection } from "../container/stats-section/stats-section";
import { TestimonialsSection } from "../container/testimonials";
import { useLocation } from "react-router-dom";

export const HomePage: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (location.hash) {
                const element = document.getElementById(location.hash.replace("#", ""));
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }
        }, 1000);
    }, [location.hash]);

    return (
        <>
            <Row>
                <WelcomeSection />
                <AboutSection />
                {/* <AgendaSection /> */}
                <StatsSection />
                <SpeakersSection />
                <ExhibitorsSection />
                {/* <CTASection /> */}
                <TestimonialsSection />
                <CompanyJoinSection />
                {/* <TicketSection /> */}
                <Footer />
            </Row>
        </>
    );
};
