import { Col, Dropdown, Menu, Row, Space } from "antd";
import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { JobStackLogo, Navbar } from "../components";
import "../components/navbar/styles/navbar.css";
import SideDrawerBurger from "../components/sidebar/side-drawer-burger/side-drawer-burger";
import { ButtonType, TAButton } from "../components/ta-button";
import { useClickedOutside, useMedia } from "../hooks";
import configProxy from "../utils/config";
import { AiOutlineDown } from "react-icons/ai";

export const TANavbar: React.FC<HTMLAttributes<HTMLBaseElement> & { onBurgerClick: () => any }> = ({
    onBurgerClick
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useClickedOutside(false);
    const media = configProxy.defaultExpandScreenSize;
    const desktop = useMedia(media);

    const switchMenu = (e: any) => {
        if (e.domEvent) e.domEvent.stopPropagation();
        else e.stopPropagation();
        setIsComponentVisible((prevState) => !prevState);
    };

    return (
        <Navbar>
            <Row className="w-100">
                <Col xs={0} xl={1}></Col>
                <Col xs={19} lg={4}>
                    <Link to="/">
                        <JobStackLogo />
                    </Link>
                </Col>
                <Col xs={5} lg={0}>
                    <SideDrawerBurger onClick={onBurgerClick} media={media} />
                </Col>
                <Col xs={0} md={18} lg={20} xl={19} xxl={18}>
                    {desktop ? (
                        <Navbar.NavigationList>
                            {/* <Navbar.NavigationItem>
                                <a> Get Tickets</a>
                            </Navbar.NavigationItem> */}
                            {/* <Navbar.NavigationItem>
                                <a href="#agenda">Agenda</a>
                            </Navbar.NavigationItem> */}
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="d-flex">
                                    <Navbar.NavigationItem>
                                        <a href="#speakers">Speakers</a>
                                    </Navbar.NavigationItem>
                                    <Navbar.NavigationItem>
                                        <a href="#exhibitors">Exhibitors</a>
                                    </Navbar.NavigationItem>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Navbar.NavigationItem>
                                        <div className="d-flex align-items-center">
                                            <a
                                                href="https://www.youtube.com/playlist?list=PLPdJSS01sOFwHIvXTIx9m6lx5hNm4Spkf"
                                                target="_blank"
                                            >
                                                <TAButton btnType={ButtonType.SECONDARY}>Explore Our Sessions</TAButton>
                                            </a>
                                        </div>
                                    </Navbar.NavigationItem>
                                </div>
                            </div>

                            <Navbar.NavigationItem
                                style={{ backgroundColor: "#fff", color: "#000", padding: "0 15px", width: "200px" }}
                            >
                                <Dropdown
                                    overlay={
                                        <Menu
                                            style={{
                                                marginTop: "10px",
                                                borderRadius: 0,
                                                width: "175px"
                                            }}
                                        >
                                            <Menu.Item key="1">
                                                <a href="#exhibitor-request">
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        Exhibitor
                                                    </span>
                                                </a>
                                            </Menu.Item>
                                            {/* <Menu.Item key="1">
                                                <a
                                                    href="https://docs.google.com/forms/d/e/1FAIpQLSe9cv3-xcbdnc2iehTuDo7iNPI7L8sdwkHwWUtUCV7qmXG2Sg/viewform"
                                                    target="_blank"
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        Community Partner
                                                    </span>
                                                </a>
                                            </Menu.Item> */}
                                            <Menu.Item key="1">
                                                <a href="https://forms.gle/gqaYmhUnPsy4b5498" target="_blank">
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        Speaker
                                                    </span>
                                                </a>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                    placement="top"
                                >
                                    <Space
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "40px"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                whiteSpace: "nowrap",
                                                lineHeight: "24px",
                                                fontWeight: 500
                                            }}
                                        >
                                            Join As...
                                        </span>
                                        <AiOutlineDown />
                                    </Space>
                                </Dropdown>
                            </Navbar.NavigationItem>
                        </Navbar.NavigationList>
                    ) : (
                        false
                    )}
                </Col>
            </Row>
        </Navbar>
    );
};
