import { Col, Divider, Row } from "antd";
import { HTMLAttributes } from "react";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import { ButtonType, TAButton } from "../../components";
import { Colors } from "../../constants";
import { FooterContainer } from "./styles/footer";
const JobStackLogo = require("../../assets/images/jobstack-logo-black.png");
const PlayStoreLogo = require("../../assets/images/google-button.png");
const AppleStoreLogo = require("../../assets/images/app-store-button.png");

export const Footer: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <FooterContainer id="footer">
            <Row
                gutter={24}
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#fff", minHeight: "500px" }}
            >
                <Divider />

                <Col lg={22} xl={21}>
                    <Row className="mb-5">
                        <img src={JobStackLogo} alt="jobstack logo" loading="lazy" />
                    </Row>
                    <Row style={{ marginBottom: "100px" }}>
                        <Col xs={24} md={8} lg={4} xl={3} className="mb-3 mb-md-0">
                            <a style={{ color: "#000", fontSize: "1rem", fontWeight: 500 }} href="#speakers">
                                Speakers
                            </a>
                        </Col>
                        <Col xs={24} md={8} lg={4} xl={3} className="mb-3 mb-md-0">
                            <a style={{ color: "#000", fontSize: "1rem", fontWeight: 500 }} href="#exhibitors">
                                Exhibitors
                            </a>
                        </Col>

                        <Col xs={24} md={8} lg={4} xl={3} className="mb-3 mb-md-0">
                            <div className="d-flex align-items-center">
                                <a href="#exhibitor-request">
                                    <TAButton
                                        btnType={ButtonType.PRIMARY}
                                        style={{ color: "#fff", fontSize: "1rem", fontWeight: 500 }}
                                        colorTheme="#000"
                                    >
                                        Become an Exhibitor
                                    </TAButton>
                                </a>
                            </div>
                        </Col>
                        <Col xl={2}></Col>
                        <Col xl={8}>
                            <div className="d-flex justify-content-end"></div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="d-flex align-items-center justify-content-md-between">
                        <Col xl={20} className="mb-3 mb-md-0">
                            <p style={{ color: Colors.TA_GREY_2, fontWeight: 500 }}>
                                © JobStack_ by talents arena © All Rights Reserved 2025
                            </p>
                        </Col>
                        <Col
                            xl={2}
                            className="d-flex align-items-center justify-content-between justify-content-md-end"
                        >
                            <a
                                href="https://www.facebook.com/jobstack.talentsarena.net"
                                target="_blank"
                                style={{ color: "#000" }}
                            >
                                <AiFillFacebook size={35} />
                            </a>
                            <a
                                href="https://www.linkedin.com/showcase/jobstack-talentsarena"
                                target="_blank"
                                style={{ color: "#000" }}
                            >
                                <AiFillLinkedin size={35} />
                            </a>
                            <a href="https://twitter.com/jobstack_TA" target="_blank" style={{ color: "#000" }}>
                                <FaSquareXTwitter size={30} />
                            </a>

                            <a
                                href="https://www.youtube.com/channel/UCZNjIlBEmV0qrXZloHO2fkA"
                                style={{ color: "#000" }}
                                target="_blank"
                            >
                                <AiFillYoutube size={45} />
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FooterContainer>
    );
};
