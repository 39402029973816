import { useQuery } from "@tanstack/react-query";
import { Col, Row, Skeleton } from "antd";
import { HTMLAttributes, useEffect, useState } from "react";
import { CompanyCard } from "../../components";
import jobstackService from "../../services/jobstack.service";
import { ExhibitorsSectionContainer } from "./styles/exhibitors-section";
import { IExhibitor } from "../../types";

export const ExhibitorsSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const [mainSponsors, setMainSponsors] = useState<IExhibitor[]>([]);
    const [platinumExhibitors, setPlatinumExhibitors] = useState<IExhibitor[]>([]);
    const [goldExhibitors, setGoldExhibitors] = useState<IExhibitor[]>([]);
    const [silverExhibitors, setSilverExhibitors] = useState<IExhibitor[]>([]);
    const [virtualExhibitors, setVirtualSponsors] = useState<IExhibitor[]>([]);

    const { data: exhibitorsData, isLoading: exhibitorsDataLoading } = useQuery({
        queryKey: ["exhibitors"],
        queryFn: () =>
            jobstackService.getExhibitors({
                page: 1,
                pageSize: 100
            }),
        keepPreviousData: true
    });

    useEffect(() => {
        if (exhibitorsData?.data?.length) {
            exhibitorsData.data.forEach((exhibitor) => {
                if (exhibitor.sponsorType === "main") setMainSponsors((prev) => [...prev, exhibitor]);
                if (exhibitor.sponsorType === "platinum") setPlatinumExhibitors((prev) => [...prev, exhibitor]);
                if (exhibitor.sponsorType === "gold") setGoldExhibitors((prev) => [...prev, exhibitor]);
                if (exhibitor.sponsorType === "silver") setSilverExhibitors((prev) => [...prev, exhibitor]);
                if (exhibitor.sponsorType === "virtual") setVirtualSponsors((prev) => [...prev, exhibitor]);
            });
        }
    }, [exhibitorsData]);

    const sponsorsMapper = [
        {
            title: `Previous Main Sponsors`,
            dataSource: mainSponsors
        },
        {
            title: `Previous Platinum Sponsors`,
            dataSource: platinumExhibitors
        },
        {
            title: `Previous Gold Sponsors`,
            dataSource: goldExhibitors
        },
        {
            title: `Previous Silver Sponsors`,
            dataSource: silverExhibitors
        },
        {
            title: `Previous Virtual Exhibitors`,
            dataSource: virtualExhibitors
        }
    ];

    const renderExhibitors = () => {
        return sponsorsMapper.map((sponsor) => {
            let highlightSection = false;
            if (
                sponsor.title === "Main Sponsors" ||
                sponsor.title === "Platinum Sponsors" ||
                sponsor.title === "Gold Sponsors" ||
                sponsor.title === "Silver Sponsors"
            )
                highlightSection = true;

            return sponsor.dataSource?.length ? (
                <Row key={sponsor.title} gutter={24} className="d-flex" style={{ backgroundColor: "#fff" }}>
                    <Col xs={24}>
                        <Row className="d-flex align-items-center mb-3">
                            <h2 style={{ color: "#000" }} className="me-3">
                                {sponsor.title}
                            </h2>
                        </Row>
                        <Row
                            gutter={16}
                            className={`d-flex ${
                                highlightSection ? "justify-content-around" : "justify-content-start"
                            }`}
                        >
                            {sponsor.dataSource.map((s) => (
                                <Col xs={12} sm={12} lg={8} xl={6} key={s.id} className="mb-3">
                                    <CompanyCard imgSource={s.logo} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            ) : (
                false
            );
        });
    };

    return (
        <ExhibitorsSectionContainer id="exhibitors">
            <Skeleton active loading={exhibitorsDataLoading}>
                {renderExhibitors()}
            </Skeleton>
        </ExhibitorsSectionContainer>
    );
};
